import type { TrackModel } from "@/data/Types";
import tracks from "@/data/tracks";

export interface LibraryEntity {
	findTrack(_id: string): TrackModel | null;
}

export class Library implements LibraryEntity {
	public allTracks: TrackModel[];
	public freeTracks: TrackModel[];
	public cafeTracks: TrackModel[];
	public soundscapeTracks: TrackModel[];
	public soundColorTracks: TrackModel[];
	public brainWaveTracks: TrackModel[];
	public creativityTracks: TrackModel[];
	public stimulationTracks: TrackModel[];
	public focusTracks: TrackModel[];
	public energyTracks: TrackModel[];
	public relaxationTracks: TrackModel[];
	public meditationTracks: TrackModel[];
	public mindfulnessTracks: TrackModel[];
	public productivityTracks: TrackModel[];
	public sleepTracks: TrackModel[];

	constructor() {
		this.allTracks = tracks;
		this.freeTracks = tracks.filter((track) => !track.isPremium);
		this.cafeTracks = tracks.filter((track) => track.collection === "cafe");
		this.soundscapeTracks = tracks.filter(
			(track) => track.collection === "soundscape",
		);
		this.soundColorTracks = tracks.filter(
			(track) => track.collection === "soundColor",
		);
		this.brainWaveTracks = tracks.filter(
			(track) => track.collection === "brainWave",
		);
		this.creativityTracks = tracks.filter((track) =>
			track.effectLabels.includes("creativity"),
		);
		this.stimulationTracks = tracks.filter((track) =>
			track.effectLabels.includes("stimulation"),
		);
		this.focusTracks = tracks.filter((track) =>
			track.effectLabels.includes("focus"),
		);
		this.energyTracks = tracks.filter((track) =>
			track.effectLabels.includes("energy"),
		);
		this.relaxationTracks = tracks.filter((track) =>
			track.effectLabels.includes("relaxation"),
		);
		this.meditationTracks = tracks.filter((track) =>
			track.effectLabels.includes("meditation"),
		);
		this.mindfulnessTracks = tracks.filter((track) =>
			track.effectLabels.includes("mindfulness"),
		);
		this.productivityTracks = tracks.filter((track) =>
			track.effectLabels.includes("productivity"),
		);
		this.sleepTracks = tracks.filter((track) =>
			track.effectLabels.includes("sleep"),
		);
	}

	findTrack(_id: string): TrackModel | null {
		const track = this.allTracks.find((t) => t.id === _id);
		return track ?? null;
	}
}
