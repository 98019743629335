import type { TrackModel } from "./Types";
import gamma from "../assets/trackImages/brainWaves/gamma.png";
import beta from "../assets/trackImages/brainWaves/beta.png";
import alpha from "../assets/trackImages/brainWaves/alpha.png";
import theta from "../assets/trackImages/brainWaves/theta.png";
import delta from "../assets/trackImages/brainWaves/delta.png";

export const brainWaveTracks: TrackModel[] = [
	{
		id: "gamma_50hz_017062",
		slug: "gamma_50hz",
		name: "Gamma Waves - 50Hz",
		releaseDate: "",
		isPremium: true,
		description: "2025-03-03T11:30:05",
		type: "file",
		collection: "brainWave",
		imageUrl: gamma,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Heightened perception",
			"Cognitive enhancement",
			"Peak awareness",
		],
		effectLabels: ["energy", "stimulation", "focus", "productivity"],
	},
	{
		id: "gamma_80hz_016706",
		slug: "gamma_80hz",
		name: "Gamma Waves - 80Hz",
		releaseDate: "",
		isPremium: true,
		description: "2025-03-03T11:30:05",
		type: "file",
		collection: "brainWave",
		imageUrl: gamma,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Heightened perception",
			"Cognitive enhancement",
			"Peak awareness",
		],
		effectLabels: ["energy", "stimulation", "focus", "productivity"],
	},
	{
		id: "gamma_500hz_016863",
		slug: "gamma_500hz",
		name: "Gamma Waves - 500Hz",
		releaseDate: "",
		isPremium: true,
		description: "2025-03-03T11:30:05",
		type: "file",
		collection: "brainWave",
		imageUrl: gamma,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Heightened perception",
			"Cognitive enhancement",
			"Peak awareness",
		],
		effectLabels: ["energy", "stimulation", "focus", "productivity"],
	},
	{
		id: "beta_50hz_016607",
		slug: "beta_50hz",
		name: "Beta Waves - 50Hz",
		releaseDate: "2025-03-03T11:30:06",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: beta,
		album: null,
		location: null,
		positiveEffectDescription: ["Focused attention", "Activity", "Awake"],
		effectLabels: ["energy", "focus", "stimulation", "productivity"],
	},
	{
		id: "beta_80hz_016849",
		slug: "beta_80hz",
		name: "Beta Waves - 80Hz",
		releaseDate: "2025-03-03T11:30:06",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: beta,
		album: null,
		location: null,
		positiveEffectDescription: ["Focused attention", "Activity", "Awake"],
		effectLabels: ["energy", "focus", "stimulation", "productivity"],
	},
	{
		id: "beta_400hz_016620",
		slug: "beta_400hz",
		name: "Beta Waves - 400Hz",
		releaseDate: "2025-03-03T11:30:06",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: beta,
		album: null,
		location: null,
		positiveEffectDescription: ["Focused attention", "Activity", "Awake"],
		effectLabels: ["energy", "focus", "stimulation", "productivity"],
	},
	{
		id: "alpha_50hz_016619",
		slug: "alpha_50hz",
		name: "Alpha Waves - 50Hz",
		releaseDate: "2025-03-03T11:30:07",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: alpha,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Positive thinking",
			"Fast Learning",
			"relaxation",
		],
		effectLabels: ["relaxation", "energy", "focus", "stimulation"],
	},
	{
		id: "alpha_80hz_016870",
		slug: "alpha_80hz",
		name: "Alpha Waves - 80Hz",
		releaseDate: "2025-03-03T11:30:07",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: alpha,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Positive thinking",
			"Fast Learning",
			"relaxation",
		],
		effectLabels: ["relaxation", "energy", "focus", "stimulation"],
	},
	{
		id: "alpha_300hz_016608",
		slug: "alpha_300hz",
		name: "Alpha Waves - 300Hz",
		releaseDate: "2025-03-03T11:30:07",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: alpha,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Positive thinking",
			"Fast Learning",
			"relaxation",
		],
		effectLabels: ["relaxation", "energy", "focus", "stimulation"],
	},
	{
		id: "theta_50hz_016870",
		slug: "theta_50hz",
		name: "Theta Waves - 50Hz",
		releaseDate: "2025-03-03T11:30:08",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: theta,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Learning",
			"Creativity",
			"Deep meditation",
			"Flow states",
		],
		effectLabels: ["creativity", "meditation", "stimulation", "focus"],
	},
	{
		id: "theta_80hz_016619",
		slug: "theta_80hz",
		name: "Theta Waves - 80Hz",
		releaseDate: "2025-03-03T11:30:08",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: theta,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Learning",
			"Creativity",
			"Deep meditation",
			"Flow states",
		],
		effectLabels: ["creativity", "meditation", "stimulation", "focus"],
	},
	{
		id: "theta_200hz_017123",
		slug: "theta_200hz",
		name: "Theta Waves - 200Hz",
		releaseDate: "2025-03-03T11:30:08",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: theta,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Learning",
			"Creativity",
			"Deep meditation",
			"Flow states",
		],
		effectLabels: ["creativity", "meditation", "stimulation", "focus"],
	},
	{
		id: "delta_50hz_016621",
		slug: "delta_50hz",
		name: "Delta Waves - 50Hz",
		releaseDate: "2025-03-03T11:30:09",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: delta,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Deep sleep",
			"Lose of body awareness",
			"Healing and regeneration",
		],
		effectLabels: ["sleep", "meditation", "mindfulness"],
	},
	{
		id: "delta_80hz_016863",
		slug: "delta_80hz",
		name: "Delta Waves - 80Hz",
		releaseDate: "2025-03-03T11:30:09",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: delta,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Deep sleep",
			"Lose of body awareness",
			"Healing and regeneration",
		],
		effectLabels: ["sleep", "meditation", "mindfulness"],
	},
	{
		id: "delta_200hz_016624",
		slug: "delta_200hz",
		name: "Delta Waves - 200Hz",
		releaseDate: "2025-03-03T11:30:09",
		isPremium: true,
		description: "",
		type: "file",
		collection: "brainWave",
		imageUrl: delta,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Deep sleep",
			"Lose of body awareness",
			"Healing and regeneration",
		],
		effectLabels: ["sleep", "meditation", "mindfulness"],
	},
];
