import { brainWaveTracks } from "./brainWaves";
import { cafeTracks } from "./cafeTracks";
import { soundColorTracks } from "./soundColorTracks";
import { soundscapeTracks } from "./soundscapeTracks";
import type { TrackModel } from "./Types";

const tracks: TrackModel[] = [
	...cafeTracks,
	...soundscapeTracks,
	...soundColorTracks,
	...brainWaveTracks,
];

export default tracks;
