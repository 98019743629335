import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { userAnalytics } from "@/lib/userAnalytics";
import getPremiumImage from "@/assets/get-premium-2.png";

interface TrackModalProps {
	isOpen: boolean;
	trackId?: string;
	close: () => void;
}

// TODO: update this to handle more than one alert type

export function UpgradeToPremiumModal({
	isOpen,
	close,
	trackId,
}: TrackModalProps) {
	const navigate = useNavigate();

	useEffect(() => {
		if (isOpen) {
			userAnalytics.tracKModalOpened("upgrade_modal", trackId);
		}
	}, [isOpen, trackId]);

	const handleClick = () => {
		userAnalytics.trackModalConverted("upgrade_modal", trackId);
		close();
		navigate("/pricing");
	};

	return (
		<Dialog open={isOpen} onOpenChange={close}>
			<DialogContent className="sm:max-w-[500px] p-0 overflow-hidden">
				<div className="flex flex-col">
					<div className="hidden md:block relative w-full aspect-square">
						<img
							src={getPremiumImage}
							alt="Premium upgrade"
							className="object-cover"
						/>
					</div>
					<div className="p-6">
						<DialogHeader className="mb-4">
							<DialogTitle className="text-2xl">Upgrade For Access</DialogTitle>
							<DialogDescription>
								To access this track and enjoy uninterrupted listening, please
								upgrade to our premium service.
							</DialogDescription>
							<DialogDescription className="">
								All premium subscriptions include a{" "}
								<span className="font-bold">3-day free trial</span>, so you can{" "}
								<span className="font-bold">try before you buy</span>!
							</DialogDescription>
						</DialogHeader>

						<div className="flex flex-col gap-3 mt-6">
							<Button className="w-full" onClick={handleClick}>
								Upgrade Now
							</Button>
							<Button variant="ghost" className="w-full" onClick={close}>
								Maybe Later
							</Button>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
