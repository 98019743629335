import type { TrackModel } from "@/data/Types";

export interface TrackEntityArgs {
	trackData: TrackModel;
	// convert this to a generic interface for other devices other than HTMLAudioElement (web)
	deviceAudioController: HTMLAudioElement;
	masterVolume: number; // optional master volume to set initial value
	volume?: number;
}

export interface TrackEntity {
	id: TrackModel["id"];
	name: TrackModel["name"];
	slug: TrackModel["slug"];
	isPremium: TrackModel["isPremium"];
	description: TrackModel["description"] | null;
	volume: number;
	setMasterVolume(_volume: number): void;
	setTrackVolume(_volume: number): void;
	play(): Promise<void>;
	pause(): void;
	onEnded(callback: () => void): void;
}

export class Track implements TrackEntity {
	private _trackData: TrackModel;
	public trackVolume: number;
	public masterVolume: number;
	public id: TrackModel["id"];
	public name: TrackModel["name"];
	public slug: TrackModel["slug"];
	public isPremium: TrackModel["isPremium"];
	public description: TrackModel["description"] | null;
	public device: HTMLAudioElement;

	constructor(_args: TrackEntityArgs) {
		this.id = _args.trackData.id;
		this.name = _args.trackData.name;
		this.slug = _args.trackData.slug;
		this.isPremium = _args.trackData.isPremium;
		this.description = _args.trackData.description;
		this.device = _args.deviceAudioController;
		this.masterVolume = _args.masterVolume;
		this.trackVolume = _args.volume ?? 1.0;
		this._trackData = _args.trackData;
	}

	public get trackData(): TrackModel {
		return this._trackData;
	}

	// track only stores its personal volume level, normalized 0-1
	public get volume(): number {
		return this.trackVolume;
	}

	public setTrackVolume(_volume: number): void {
		// This sets the track-level volume
		this.trackVolume = _volume;
		this.device.volume = this.trackVolume * this.masterVolume;
	}

	public setMasterVolume(_volume: number): void {
		// This sets the track-level volume
		this.masterVolume = _volume;
		this.device.volume = this.trackVolume * this.masterVolume;
	}

	public async play(): Promise<void> {
		if (this.device.paused || this.device.ended) {
			await this.device.play();
		}
	}

	public pause(): void {
		if (!this.device.paused) {
			this.device.pause();
		}
	}

	public onEnded(callback: () => void): void {
		const handler = () => {
			this.device.removeEventListener("ended", handler);
			callback();
		};
		this.device.addEventListener("ended", handler);
	}
}
