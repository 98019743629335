import { Play, Pause, Volume2, VolumeX } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Slider } from "@/components/ui/slider";
import { useAudio } from "@/providers/audio-provider";
import { Link } from "react-router";
import { cn } from "@/lib/utils";

export function AudioPlayer({ className }: { className?: string }) {
	const {
		currentTrack,
		handleMuteToggle,
		isPlaying,
		handlePlay,
		handlePause,
		handleMasterVolumeChange,
		masterVolume,
	} = useAudio();

	const handleVolumeChange = (newVolume: number[]) => {
		handleMasterVolumeChange(newVolume[0]);
	};

	return (
		<>
			{currentTrack && (
				<div
					className={cn(
						`
            fixed bottom-16 left-0 right-0 bg-background border p-2 flex items-center justify-between 
            md:absolute md:bottom-5 md:mr-4 md:px-2 md:shadow md:rounded-lg
            `,
						className,
					)}
				>
					<Button
						size="icon"
						className="h-16 w-16 [&_svg]:size-6"
						onClick={() =>
							isPlaying ? handlePause() : handlePlay(currentTrack)
						}
					>
						{isPlaying ? (
							<Pause fill="#000" strokeWidth={1} className="text-white" />
						) : (
							<Play
								fill="#000"
								strokeWidth={1}
								className="h-4 w-4 text-white"
							/>
						)}
					</Button>
					<div className="flex-1 mx-2 truncate text-lg font-medium">
						<Link
							to={
								currentTrack.collection === "ad"
									? "/pricing"
									: `/library/track/${currentTrack.slug}`
							}
						>
							{currentTrack.name}
						</Link>
					</div>

					<Button
						className="mr-2 hidden md:flex"
						variant="ghost"
						size="icon"
						onClick={handleMuteToggle}
					>
						{masterVolume === 0 ? (
							<VolumeX className="h-4 w-4" />
						) : (
							<Volume2 className="h-4 w-4" />
						)}
					</Button>
					<Slider
						min={0}
						max={1}
						step={0.01}
						value={[masterVolume]}
						onValueChange={handleVolumeChange}
						className="w-24 mr-1 hidden md:flex"
					/>
				</div>
			)}
		</>
	);
}
