import type { TrackModel } from "@/data/Types";
import { Library } from "./Library";
import { Track } from "./Track";
import eventBus from "@/lib/eventBus";
import Timer from "@/lib/Timer";
import { adTracks as allAdTracks } from "@/data/adTracks";

export interface PlaybackControllerEntity {
	getCurrentTrack(): Track | null;
	getPreloadedTracks(): Map<string, Track>;
	play(_trackId: string, _shouldPauseOtherPlayback?: boolean): Promise<void>;
	pause(_trackId?: string): void;
	setMasterVolume(_volume: number): void;
	setShouldPlayAds(_shouldPlayAds: boolean): void;
	setTrackVolume(_trackId: string, _volume: number): void;
}

export interface PlaybackControllerOptions {
	masterVolume?: number;
	shouldPlayAds?: boolean;
}

class PlaybackController implements PlaybackControllerEntity {
	// don't allow direct access to the master volume, it needs to be mutated in a specific way
	private _masterVolume;

	private adTracks: Track[] = [];
	private currentTrack: Track | null = null;
	private defaultAdTimerLength = 25 * 60; // 25mins: default length of an ad in seconds, used for the timer
	private isAdPlaying = false;
	private library: Library;
	private loadedTracks: Map<Track["id"], Track> = new Map();
	private preferredAudioFormat: "mp3" | "ogg";
	private shouldPlayAds = false;
	private timer: Timer | null;
	private trackBeforeAdPlay: Track | null = null;

	constructor(_options: PlaybackControllerOptions = {}) {
		// generate a new audio controller instances to confirm playback options
		const testAudioElement = new Audio();
		this._masterVolume = _options.masterVolume ?? 0.8;
		this.shouldPlayAds = _options.shouldPlayAds ?? true;
		this.preferredAudioFormat = testAudioElement.canPlayType("audio/ogg")
			? "ogg"
			: "mp3";
		this.library = new Library();
		this.timer = this.shouldPlayAds
			? new Timer(this.defaultAdTimerLength)
			: null;

		// preload ad tracks if ads are enabled
		if (this.shouldPlayAds) {
			for (const adTrack of allAdTracks) {
				const track = this.loadTrack(adTrack);
				this.adTracks.push(track);
			}
		}

		// bind the event listeners for the freemium timer expiration to play ads
		eventBus.on(
			"freemiumTimerExpired",
			this.handleFreemiumTimerExpired.bind(this),
		);
	}

	private async handleFreemiumTimerExpired(): Promise<void> {
		// we should only be doing this if ads are enabled, otherwise bail out.
		if (!this.shouldPlayAds) return;

		// update the state to indicate an ad is playing
		this.isAdPlaying = true;
		// store the current track before playing an ad, so we can resume it later
		this.trackBeforeAdPlay = this.currentTrack;
		// pause the current track
		this.pause();

		try {
			// randomly select an ad track from our list
			const adTrackToPlay =
				this.adTracks[Math.floor(Math.random() * this.adTracks.length)];

			// start the ad playback sequence
			await this.playAd(adTrackToPlay.id); // default to the only add we have right now

			// after the ad has played, resume the previous track
			this.resumePreviousTrack();
		} catch (error) {
			console.error("Failed to play ad", error);
		}

		this.isAdPlaying = false;
	}

	/** Load a track and prepare it for playback */
	private loadTrack(_trackData: TrackModel, _trackVolume = Number(1)): Track {
		if (!this.loadedTracks.has(_trackData.id)) {
			const url = _trackData.isPremium
				? `https://premium-audio.coffitivity.com/${_trackData.id}.${this.preferredAudioFormat}`
				: `https://audio.coffitivity.com/${_trackData.id}.${this.preferredAudioFormat}`;

			// create the playback controller so we can pass it to the Track Entity
			const audio = new Audio(url);

			// create a track manager
			const track = new Track({
				trackData: _trackData,
				deviceAudioController: audio,
				masterVolume: this.masterVolume,
			});

			// add the track to the loaded tracks map
			this.loadedTracks.set(_trackData.id, track);
			return track;
		}

		// If the track is already loaded, return the existing instance
		return this.loadedTracks.get(_trackData.id)!;
	}

	private async playAd(_trackId: string): Promise<void> {
		// ad tracks should be preloaded but in case not do that now
		let adTrack = this.loadedTracks.get(_trackId);
		if (!adTrack) {
			const adFromLib = this.library.findTrack(_trackId);
			if (!adFromLib) {
				console.error(`Ad track with ID ${_trackId} not found.`);
				throw new Error("Ad track not found");
			}
			adTrack = this.loadTrack(adFromLib);
		}

		// set the current track to the ad track
		this.currentTrack = adTrack;

		// return a promise that will get executed by the evoking method
		return new Promise((resolve) => {
			// play the ad track
			adTrack.play(); // this will pause any other playing tracks, should be paused already, but just incase

			// notify the frontend that the track has changed to the ad
			eventBus.emit("trackChanged", adTrack.trackData);
			eventBus.emit("showModal", {
				id: "TIME_LIMIT", // this will show the upgrade modal
				trackId: this.trackBeforeAdPlay!.id, // pass the track id for analytics
			});

			// once the ad has concluded, remove the modal and resolve the promise for the caller
			// to pick up on the next action
			adTrack.onEnded(() => {
				eventBus.emit("closeModal"); // close the modal after the ad finishes
				resolve();
			});
		});
	}

	private async resumePreviousTrack(): Promise<void> {
		// if there was no track before the ad, just bail
		if (!this.trackBeforeAdPlay) return;

		// continue playing the previous track that was interrupted by the ad
		await this.trackBeforeAdPlay.play();

		// set the current track back to the previous track
		this.currentTrack = this.trackBeforeAdPlay;
		// clear the reference to the previous track since we are resuming it
		this.trackBeforeAdPlay = null;

		// notify the frontend that the track has changed back to the original track
		eventBus.emit("trackChanged", this.currentTrack.trackData);

		// Restart timer for next ad cycle
		this.timer?.reset();
		this.timer?.start("freemiumTimerExpired");
	}

	get masterVolume(): number {
		return this._masterVolume;
	}

	/** Get the current track */
	getCurrentTrack(): Track | null {
		return this.currentTrack;
	}

	getPreloadedTracks(): Map<string, Track> {
		return this.loadedTracks;
	}

	/** Play a specific track */
	async play(
		_trackId: string,
		_shouldPauseOtherPlayback = true,
		_shouldSkipModal = false, // allow skipping the modal for ads
	): Promise<void> {
		// Pause the currently playing track if there is one
		if (_shouldPauseOtherPlayback && this.currentTrack) {
			this.pause(this.currentTrack.id);
		}

		// if the current track is an ad they need to finish before playing another track
		if (this.isAdPlaying) {
			// play ad
			this.playAd(this.currentTrack!.id);
			return; // return so the callstack doesn't pick up here
		}

		// check if the track is preloaded in memory, otherwise do so
		let loadedAudio = this.loadedTracks.get(_trackId);
		if (!loadedAudio) {
			// the track hasn't been loaded yet, so load it, and then play it
			const trackFromLib = this.library.findTrack(_trackId);

			if (!trackFromLib) {
				// cannot find the track in the library, log an error and return
				console.error(`Track with ID ${_trackId} not found in the library.`);
				return;
			}

			const newAudio = this.loadTrack(trackFromLib);
			loadedAudio = newAudio;
		}

		// Play the track
		await loadedAudio.play();

		// set the current track in memory
		this.currentTrack = loadedAudio;
		// notify the frontend that the track has changed
		eventBus.emit("trackChanged", loadedAudio.trackData);

		// start the timer if ads are enabled
		if (this.shouldPlayAds) {
			this.timer?.reset(); // reset the timer to start fresh
			this.timer?.start("freemiumTimerExpired");
		}
	}

	/** Pause playback */
	pause(_trackId?: string): void {
		if (!this.currentTrack) {
			console.log("No track is currently playing.");
			return;
		}

		// pause the timer
		this.timer?.pause;

		// pause all playing tracks if no trackId is provided
		if (!_trackId) {
			for (const audio of this.loadedTracks.values()) {
				audio.pause();
			}

			return;
		}

		// pause the specific track
		const audio = this.loadedTracks.get(_trackId);
		if (audio) {
			audio.pause();
		}
	}

	/** Set volume for the audio player **/
	setMasterVolume(_volume: number) {
		this._masterVolume = _volume;
		for (const track of this.loadedTracks.values()) {
			track.setMasterVolume(this._masterVolume);
		}
	}

	setShouldPlayAds(_shouldPlayAds: boolean): void {
		this.shouldPlayAds = _shouldPlayAds;

		// set the timer
		if (_shouldPlayAds) {
			this.timer = new Timer(this.defaultAdTimerLength);
		} else {
			this.timer = null;
		}
	}

	setTrackVolume(_trackId: string, _volume: number): void {
		if (this.loadedTracks.has(_trackId)) {
			const track = this.loadedTracks.get(_trackId)!;
			track.setTrackVolume(_volume); // Set the volume relative to the master volume
		}

		return;
	}
}

// use this singleton instance of the PlaybackController to manage playback across the app
export const playbackControllerInstance = new PlaybackController({
	masterVolume: 0.8, // default master volume
	shouldPlayAds: true, // enable ads by default
});
