import type { TrackModel } from "./Types";
import whiteNoise from "../assets/trackImages/soundColors/white_noise.png";
import pinkNoise from "../assets/trackImages/soundColors/pink_noise.png";
import brownNoise from "../assets/trackImages/soundColors/brown_noise.png";
import blueNoise from "../assets/trackImages/soundColors/blue_noise.png";
import tealNoise from "../assets/trackImages/soundColors/teal_noise.png";

export const soundColorTracks: TrackModel[] = [
	{
		id: "white_noise",
		slug: "white_noise",
		name: "White Noise",
		releaseDate: "2025-03-03T11:30:00",
		isPremium: false,
		description:
			"Helps block out distractions, making it great for sleep, focus, or masking background noise  in offices or noisy environments.",
		type: "generatedNoise",
		collection: "soundColor",
		imageUrl: whiteNoise,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Increasing concentration",
			"Improving sleep and relaxation",
			"Managing tinnitus",
		],
		effectLabels: ["focus", "relaxation", "sleep"],
		whiteNoiseConfig: {}, // default settings
	},
	{
		id: "pink_noise",
		slug: "pink_noise",
		name: "Pink Noise",
		releaseDate: "2025-03-03T11:30:01",
		isPremium: true,
		description:
			"Often used for sleep improvement and relaxation  since it mimics natural sounds like rain and rustling leaves, creating a soothing atmosphere.",
		type: "generatedNoise",
		collection: "soundColor",
		imageUrl: pinkNoise,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Falling asleep faster",
			"Staying asleep longer",
			"Improving memory",
		],
		effectLabels: ["sleep", "relaxation", "focus"],
		whiteNoiseConfig: {
			subBase: 6, // Boost deep bass
			lowBase: 4, // Slightly boost bass
			base: 2, // Slightly boost low mids
			highBase: 0, // Keep neutral
			lowMids: -2, // Reduce slightly
			mids: -4, // Reduce more
			highMids: -6, // Reduce more
			lowTreble: -8, // Reduce even more
			treble: -10, // Reduce significantly
			highTreble: -12, // Reduce highest frequencies harshly
		},
	},
	{
		id: "brown_noise",
		slug: "brown_noise",
		name: "Brown Noise",
		releaseDate: "2025-03-03T11:30:02",
		isPremium: true,
		description:
			"Great for stress relief, deep sleep, and relaxation , as its deep, warm tone resembles rolling thunder or ocean waves, providing a sense of calm.",
		type: "generatedNoise",
		collection: "soundColor",
		imageUrl: brownNoise,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Improving focus",
			"Enhancing relaxation",
			"Reducing stress",
			"Sleep aid",
		],
		effectLabels: ["focus", "relaxation", "sleep"],
		whiteNoiseConfig: {
			subBase: 15, // Strongly boost deep bass
			lowBase: 12, // Boost bass
			base: 9, // Boost low mids
			highBase: 6, // Slight low-mid boost
			lowMids: 3, // Slightly reduce mids
			mids: 0, // Near-neutral mids
			highMids: -3, // Reduce high mids
			lowTreble: -6, // Reduce treble
			treble: -9, // Strongly reduce high frequencies
			highTreble: -12, // Very strong high-frequency drop
		},
	},
	{
		id: "blue_noise",
		slug: "blue_noise",
		name: "Blue Noise",
		releaseDate: "2025-03-03T11:30:03",
		isPremium: false,
		description:
			"Ideal for tinnitus relief or boosting alertness , as its sharper, airy sound can help mask ringing in the ears or improve concentration.",
		type: "generatedNoise",
		collection: "soundColor",
		imageUrl: blueNoise,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Boosting Alertness",
			"Improving focus",
			"Managing tinnitus",
		],
		effectLabels: ["focus", "relaxation", "sleep"],
		whiteNoiseConfig: {
			subBase: -12, // Strongly reduce deep bass
			lowBase: -10, // Reduce bass
			base: -8, // Reduce low mids
			highBase: -6, // Slightly reduce mids
			lowMids: -4, // Neutral mids
			mids: -2, // Slightly boost high mids
			highMids: 0, // Keep this neutral
			lowTreble: 2, // Slight boost
			treble: 4, // Boost high frequencies
			highTreble: 6, // Strong high-frequency boost
		},
	},
	{
		id: "teal_noise",
		slug: "teal_noise",
		name: "Teal Noise",
		releaseDate: "2025-03-03T11:30:04",
		isPremium: true,
		description:
			"A unique track to Coffitivity, designed to mimic nature. Teal noise is a blend of pink and blue noise to provide a balanced sound that's both calming and energizing.",
		type: "generatedNoise",
		collection: "soundColor",
		imageUrl: tealNoise,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Immersive ASMR sensation",
			"Reducing stress and tension",
			"Enhancing relaxation",
		],
		effectLabels: ["relaxation", "focus"],
		whiteNoiseConfig: {
			subBase: -5,
			lowBase: -5,
			base: 2,
			highBase: 5,
			lowMids: 10,
			mids: 5,
			highMids: 2,
			lowTreble: 0,
			treble: -7,
			highTreble: -10,
		},
	},
];
