import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useAuth } from "./auth-provider";
import type { TrackModel } from "@/data/Types";
import { userAnalytics } from "@/lib/userAnalytics";
import { playbackControllerInstance } from "@/lib/audio/PlaybackController";
import eventBus from "@/lib/eventBus";

interface AudioContextProps {
	currentTrack: TrackModel | null;
	handleMuteToggle: () => void;
	handlePause: (_track?: TrackModel["id"]) => void;
	handleMasterVolumeChange: (volume: number) => void;
	handlePlay: (track: TrackModel) => void;
	isPlaying: boolean;
	isUserEngaged: boolean;
	masterVolume: number;
}

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

export const useAudio = () => {
	const context = useContext(AudioContext);
	if (!context) {
		throw new Error("useAudio must be used within an AudioProvider");
	}
	return context;
};

const AudioProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	// we use a single instance of the playback controller so we can always just reference the current
	const playbackControllerRef = useRef(playbackControllerInstance).current;
	const [masterVolume, setMasterVolume] = useState(
		playbackControllerRef.masterVolume,
	);
	const [previousMasterVolume, setPreviousMasterVolume] = useState(
		playbackControllerRef.masterVolume,
	);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTrack, setCurrentTrack] = useState<TrackModel | null>(null);
	const { isAuthenticated, isSubscribed } = useAuth();
	const [isUserEngaged, setIsUserEngaged] = useState(false);

	const handleKeyboardEvents = useCallback(
		(event: KeyboardEvent) => {
			const target = event.target as HTMLElement;
			const isTypingElement = ["INPUT", "TEXTAREA", "SELECT"].includes(
				target.tagName,
			);
			if (isTypingElement) return;
			if (event.key === " " || event.code === "Space") {
				event.preventDefault();
				if (isPlaying) {
					handlePause();
				} else {
					if (currentTrack) {
						handlePlay(currentTrack);
					}
				}
			}
		},
		[isPlaying, currentTrack],
	);

	/** Pause both audio and white noise */
	const handlePause = (_track?: TrackModel["id"]) => {
		// make sure there's something to pause
		if (!isPlaying || !currentTrack) return;

		playbackControllerRef.pause(_track);
		setIsPlaying(false);

		// track playback paused
		if (currentTrack) {
			userAnalytics.trackPlaybackPaused(currentTrack?.id);
		}
	};

	/** Play the currently selected track (either audio file or white noise) */
	const handlePlay = (track: TrackModel) => {
		// we need to track when the user has engaged
		if (!isUserEngaged) setIsUserEngaged(true);

		// confirm the user is authenticated and subscribed if the track is premium
		if (track.isPremium && !(isAuthenticated && isSubscribed)) {
			eventBus.emit("showModal", {
				id: "PREMIUM_REQUIRED", // this will show the upgrade modal
				trackId: track.id, // pass the track id for analytics
			});

			return;
		}

		// If the same track is already playing, do nothing
		if (currentTrack?.id === track.id && isPlaying) {
			return;
		}

		// play the track
		playbackControllerRef.play(track.id);
	};

	/** Set audio volume */
	const handleMasterVolumeChange = (volume: number) => {
		const normalizedVolume = volume > 1 ? volume / 100 : volume;
		setMasterVolume(normalizedVolume);

		playbackControllerRef.setMasterVolume(normalizedVolume);
	};

	/** Mute/unmute volume */
	const handleMuteToggle = () => {
		if (masterVolume > 0) {
			setPreviousMasterVolume(masterVolume);
			handleMasterVolumeChange(0);
		} else {
			handleMasterVolumeChange(previousMasterVolume);
		}
	};

	// make sure to capture keyboard events
	useEffect(() => {
		window.addEventListener("keydown", handleKeyboardEvents);
		return () => window.removeEventListener("keydown", handleKeyboardEvents);
	}, [handleKeyboardEvents]);

	// ensure the frontend updates with the playback controller changes
	useEffect(() => {
		const handleTrackChange = (track: TrackModel) => {
			setCurrentTrack(track);
			setIsPlaying(true);
			userAnalytics.trackPlaybackStarted(track.id);
		};

		eventBus.on("trackChanged", handleTrackChange);

		return () => {
			eventBus.off("trackChanged", handleTrackChange);
		};
	}, []);

	// ensure the playback controller is configured to not play ds if the user is subscribed
	useEffect(() => {
		if (isSubscribed) {
			playbackControllerRef.setShouldPlayAds(false);
		}
	}, [isSubscribed, playbackControllerRef]);

	return (
		<AudioContext.Provider
			value={{
				currentTrack,
				handleMasterVolumeChange,
				handleMuteToggle,
				handlePause,
				handlePlay,
				isPlaying,
				isUserEngaged,
				masterVolume,
			}}
		>
			{children}
		</AudioContext.Provider>
	);
};

export default AudioProvider;
