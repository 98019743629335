import type { TrackModel } from "./Types";
import ocean_waves from "../assets/trackImages/soundscapes/ocean_waves.png";
import waterfall_2 from "../assets/trackImages/soundscapes/waterfall_2.png";
import heavy_rain_storm from "../assets/trackImages/soundscapes/heavy_rain_storm.png";
import thunderstorm from "../assets/trackImages/soundscapes/thunderstorm.png";
import birds_chirping from "../assets/trackImages/soundscapes/birds_chirping.png";
import creek from "../assets/trackImages/soundscapes/creek.png";
import river from "../assets/trackImages/soundscapes/river.png";
import airplane_cabin from "../assets/trackImages/soundscapes/airplane_cabin.png";
import train_car from "../assets/trackImages/soundscapes/train_car.png";
import subway_car from "../assets/trackImages/soundscapes/subway_car.png";
import car from "../assets/trackImages/soundscapes/car.png";
import ocean_beach from "../assets/trackImages/soundscapes/ocean_beach.png";
import jungle from "../assets/trackImages/soundscapes/jungle.png";
import heavy_wind from "../assets/trackImages/soundscapes/heavy_wind.png";
import cicadas from "../assets/trackImages/soundscapes/cicadas.png";
import wetlands from "../assets/trackImages/soundscapes/wetlands.png";

export const soundscapeTracks: TrackModel[] = [
	// {
	// 	id: "wind-chimes-1",
	//  slug: "wind-c",
	// 	name: "Wind Chimes",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: "/trackImages/soundscapes/wind_chimes.png",
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Encourages relaxation and mindfulness.",
	// 		"Can create a sense of calm or transcendence.",
	// 		"May evoke peaceful memories or spiritual feelings.",
	// 	],
	// 	effectLabels: ["mindfulness", "relaxation", "meditation",
	// },
	// {
	// 	id: "fireplace-1",
	//  slug: "fire",
	// 	name: "Fireplace",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: "/trackImages/soundscapes/fireplace.png",
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Improves feelings of warmth, security, and comfort ('hygge effect').",
	// 		"Reduces anxiety and promotes relaxation.",
	// 		"Mimics the primal association of fire with safety.",
	// 	],
	// 	effectLabels: ["relaxation", "mindfulness"],
	// },
	{
		id: "ocean_016713",
		slug: "ocean",
		name: "Ocean Waves",
		releaseDate: "2025-03-03T11:29:38",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: ocean_waves,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Promotes calmness and reduces stress.",
			"Enhances focus and creative thinking.",
		],
		effectLabels: ["relaxation", "creativity", "sleep"],
	},
	{
		id: "waterfall_016864",
		slug: "waterfall",
		name: "Waterfall",
		releaseDate: "2025-03-03T11:29:39",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: waterfall_2,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Creates a consistent white noise effect, masking disruptive sounds.",
			"Energizes and refreshes the mind.",
			"Can evoke a sense of wonder and connection to nature.",
		],
		effectLabels: ["focus", "energy", "productivity", "focus"],
	},
	// {
	// 	id: "light-rain-storm-1",
	//  slug: "light-rain-",
	// 	name: "Light Rain Storm",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: light_rain_storm,
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Provides a gentle, rhythmic sound that calms the mind.",
	// 		"Promotes feelings of safety and nostalgia.",
	// 		"Enhances focus and creativity without being intrusive.",
	// 	],
	// 	effectLabels: ["relaxation", "focus", "sleep"],
	// },
	{
		id: "heavy_rain_016733",
		slug: "heavy_rain",
		name: "Heavy Rain Storm",
		releaseDate: "2025-03-03T11:29:40",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: heavy_rain_storm,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Intense sound masking for distraction-heavy environments.",
			"Triggers deep relaxation, reducing tension.",
			"Provides dramatic ambiance, enhancing emotions or introspection.",
		],
		effectLabels: ["relaxation", "focus", "sleep"],
	},
	{
		id: "thunder_storm_016820",
		slug: "thunder_storm",
		name: "Thunderstorm",
		releaseDate: "2025-03-03T11:29:41",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: thunderstorm,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Promotes awe and introspection due to the dramatic nature of the sound.",
			"May help some people feel 'cozily contained' when indoors.",
			"Provides emotional stimulation and catharsis.",
		],
		effectLabels: ["relaxation", "stimulation", "creativity"],
	},
	{
		id: "bird_songs_016871",
		slug: "bird_songs",
		name: "Bird Songs",
		releaseDate: "2025-03-03T11:29:42",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: birds_chirping,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Improves mood and encourages a sense of well-being.",
			"Mimics the beginning of the day, aiding focus and energy.",
			"Reduces feelings of depression and promotes optimism.",
		],
		effectLabels: ["relaxation", "energy", "productivity"],
	},
	// {
	// 	id: "water-fountain-1",
	// slug: "water-fou",
	// 	name: "Water Fountain",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: water_fountain,
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Provides a soothing, steady background noise.",
	// 		"Enhances focus and diminishes stress via gentle sound repetition.",
	// 		"Creates a sense of calm in busy indoor or outdoor spaces.",
	// 	],
	// 	effectLabels: ["relaxation", "focus", "meditation"],
	// },
	{
		id: "creek_016608",
		slug: "creek",
		name: "Creek",
		releaseDate: "2025-03-03T11:29:43",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: creek,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Mimics natural tranquility, reducing stress and anxiety.",
			"Enhances mindfulness and meditation.",
			"Improves focus through gentle white noise.",
		],
		effectLabels: ["relaxation", "sleep", "sleep", "meditation"],
	},
	{
		id: "river_016706",
		slug: "river",
		name: "River",
		releaseDate: "2025-03-03T11:29:44",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: river,
		album: null,
		location: null,
		positiveEffectDescription: [],
		effectLabels: ["relaxation", "sleep", "sleep", "meditation"],
	},
	{
		id: "aircraft_016807",
		slug: "aircraft",
		name: "Airplane Cabin",
		releaseDate: "2025-03-03T11:29:45",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: airplane_cabin,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Creates a consistent 'white noise' effect that blocks out distractions.",
			"May evoke feelings of travel or nostalgia for frequent flyers.",
			"Provides a sense of steadiness and calm.",
		],
		effectLabels: ["focus", "sleep", "productivity"],
	},
	{
		id: "electric_train_016850",
		slug: "electric_train",
		name: "Electric Train Car",
		releaseDate: "2025-03-03T11:29:46",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: train_car,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Mimics movement and creates a 'traveling mindset', useful for creativity.",
			"Elicits nostalgia or comfort for regular train travelers.",
			"Provides a steady rhythm that aids focus.",
		],
		effectLabels: ["focus", "creativity"],
	},
	{
		id: "subway_017093",
		slug: "subway",
		name: "Subway Car",
		releaseDate: "2025-03-03T11:29:47",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: subway_car,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Mimics movement and creates a 'traveling mindset', useful for creativity.",
			"Elicits nostalgia or comfort for regular train travelers.",
			"Provides a steady rhythm that aids focus.",
		],
		effectLabels: ["focus", "creativity"],
	},
	{
		id: "car_035435",
		slug: "car",
		name: "Car Noise",
		releaseDate: "2025-03-03T11:29:48",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: car,
		album: null,
		location: null,
		positiveEffectDescription: [],
		effectLabels: ["focus", "creativity"],
	},
	// {
	// 	id: "underwater-ocean-1",
	// slug: "underwater-",
	// 	name: "Underwater Ocean",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: underwater_ocean,
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Evokes a sense of awe and connection to deep tranquility.",
	// 		"Promotes deep relaxation and stress relief.",
	// 		"Encourages a dreamlike immersion into nature.",
	// 	],
	// 	effectLabels: ["relaxation", "meditation", "sleep"],
	// },
	{
		id: "beach_016885",
		slug: "beach",
		name: "Ocean Beach",
		releaseDate: "2025-03-03T11:29:49",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: ocean_beach,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Promotes calmness and reduces stress.",
			"Enhances focus and creative thinking.",
			"Mimics being on vacation, evoking happiness.",
		],
		effectLabels: ["relaxation", "creativity", "sleep"],
	},
	{
		id: "jungle_016917",
		slug: "jungle",
		name: "Jungle",
		releaseDate: "2025-03-03T11:29:50",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: jungle,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Stimulates senses and creates a vibrant but soothing environment.",
			"Connects listeners to nature's diversity and dynamism.",
			"Encourages energy and sensory engagement.",
		],
		effectLabels: ["stimulation", "energy", "creativity"],
	},
	// {
	// 	id: "office-space-1",
	// slug: "office-",
	// 	name: "Office Space",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: office_space,
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Mimics a work environment, boosting focus for solo remote workers.",
	// 		"Instills productivity and professional rhythm.",
	// 		"Helps reduce loneliness for remote workers.",
	// 	],
	// 	effectLabels: ["focus", "productivity"],
	// },
	// {
	// 	id: "city-street-1",
	// slug: "city-s",
	// 	name: "City Street",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: city_street,
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Promotes energy and engagement for urban-loving individuals.",
	// 		"Masks household or workspace noise with consistent 'urban hum.'",
	// 		"Encourages interaction and inspiration.",
	// 	],
	// 	effectLabels: ["energy", "focus", "creativity"],
	// },
	// {
	// 	id: "city-park-1",
	// slug: "city",
	// 	name: "City Park",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: city_park,
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Blends calmness (via nature's cues) with vitality (by faint human activity).",
	// 		"Encourages positivity and mental clarity.",
	// 		"Relaxes the mind while fostering a connection to nature.",
	// 	],
	// 	effectLabels: ["relaxation", "energy", "meditation"],
	// },
	// {
	// 	id: "leaves-rustling-1",
	// slug: "leaves-rus",
	// 	name: "Leaves Rustling",
	// 	releaseDate: "",
	// 	isPremium: true,
	// 	description: null,
	// type: "file",
	// 	collection: "soundscape",
	// 	imageUrl: leaves_rustling,
	// 	album: null,
	// 	location: null,
	// 	positiveEffectDescription: [
	// 		"Stimulates relaxation and encourages mindfulness.",
	// 		"Enhances nature-connectedness, reducing stress.",
	// 		"Creates a grounding presence through natural sound.",
	// 	],
	// 	effectLabels: ["relaxation", "mindfulness", "meditation", "sleep"],
	// },
	{
		id: "wind_016595",
		slug: "wind",
		name: "Heavy Wind",
		releaseDate: "2025-03-03T11:29:51",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: heavy_wind,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Invokes a sense of spaciousness and freedom.",
			"Stimulates awe or calm, depending on the emotional response.",
			"Encourages reflection and grounding in untamed environments.",
		],
		effectLabels: ["mindfulness", "meditation"],
	},
	{
		id: "cicadas_016722",
		slug: "cicadas",
		name: "Cicadas",
		releaseDate: "2025-03-03T11:29:52",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: cicadas,
		album: null,
		location: null,
		positiveEffectDescription: [
			"Brings nostalgic feelings of summer or outdoor relaxation.",
			"Encourages a sense of rhythm and connection to nature.",
			"Mimics calm, warm settings like rural or tropical nights.",
		],
		effectLabels: ["relaxation", "sleep"],
	},
	{
		id: "wetlands_016846",
		slug: "wetlands",
		name: "Wetlands",
		releaseDate: "2025-03-03T11:29:53",
		isPremium: true,
		description: null,
		type: "file",
		collection: "soundscape",
		imageUrl: wetlands,
		album: null,
		location: null,
		positiveEffectDescription: [],
		effectLabels: ["relaxation", "sleep"],
	},
];
