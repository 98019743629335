import { lazy, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router";
import DashboardLayout from "@/layouts/dashboard-layout";
import AuthLayout from "@/layouts/auth-layout";
import NotFound from "./pages/not-found";
import { userAnalytics } from "@/lib/userAnalytics";

// import Test from "./pages/dashboard/test";

const About = lazy(() => import("@/pages/dashboard/about"));
const Account = lazy(() => import("@/pages/dashboard/account"));
const Checkout = lazy(() => import("@/pages/dashboard/checkout"));
const CheckoutConfirmation = lazy(
	() => import("@/pages/dashboard/checkout-confirmation"),
);
const Contact = lazy(() => import("@/pages/dashboard/contact"));
const ForgotPassword = lazy(() => import("@/pages/auth/forgot-password"));
const Home = lazy(() => import("@/pages/dashboard/home"));
const Library = lazy(() => import("@/pages/dashboard/library"));
const LogIn = lazy(() => import("@/pages/auth/log-in"));
const PremiumOffer = lazy(() => import("@/pages/auth/premium-offer"));
const Pricing = lazy(() => import("@/pages/dashboard/pricing"));
const ResetPassword = lazy(() => import("@/pages/auth/reset-password"));
const SignUp = lazy(() => import("@/pages/auth/sign-up"));
const Terms = lazy(() => import("@/pages/dashboard/terms"));
const Track = lazy(() => import("@/pages/dashboard/track"));
const TrackGroup = lazy(() => import("@/pages/dashboard/track-list"));
const VerifyEmail = lazy(() => import("@/pages/auth/verify-email"));
const V2Launch = lazy(() => import("@/pages/dashboard/v2-launch"));

function App() {
	const location = useLocation();

	useEffect(() => {
		userAnalytics.trackPageView(location.pathname);
	}, [location]);

	return (
		<Routes>
			<Route element={<DashboardLayout />}>
				<Route index element={<Home />} />

				{/* <Route path="test" element={<Test />} /> */}

				<Route path="about" element={<About />} />
				<Route path="account" element={<Account />} />
				<Route path="checkout" element={<Checkout />} />
				<Route
					path="checkout-confirmation"
					element={<CheckoutConfirmation />}
				/>
				<Route path="contact" element={<Contact />} />
				<Route path="library">
					<Route index element={<Library />} />
					{/* Route only for free tracks */}
					<Route path=":groupBy/" element={<TrackGroup />} />
					{/* All other tracks */}
					<Route path=":groupBy/:groupName" element={<TrackGroup />} />
					<Route path="track/:trackSlug" element={<Track />} />
				</Route>
				<Route path="pricing" element={<Pricing />} />
				<Route path="terms" element={<Terms />} />
				<Route path="v2-launch" element={<V2Launch />} />
			</Route>
			<Route path="auth" element={<AuthLayout />}>
				<Route path="log-in" element={<LogIn />} />
				<Route path="sign-up" element={<SignUp />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
				<Route path="premium-offer" element={<PremiumOffer />} />
				<Route path="reset-password" element={<ResetPassword />} />
				<Route path="verify-email" element={<VerifyEmail />} />
			</Route>
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default App;
