import type { TrackModel } from "./Types";
import morning_murmur from "../assets/trackImages/cafes/morning_murmur.png";
import lunchtime_lounge from "../assets/trackImages/cafes/lunchtime_lounge.png";
import university_undertones from "../assets/trackImages/cafes/university_undertones.png";
import paris_paradise from "../assets/trackImages/cafes/paris_paradise.png";
import brazil_bistro from "../assets/trackImages/cafes/brazil_bistro.png";
import texas_teahouse from "../assets/trackImages/cafes/texas_teahouse.png";
import bogota_buzz from "../assets/trackImages/cafes/bogota_buzz.png";
import colombian_coffeehouse from "../assets/trackImages/cafes/columbian_coffeehouse.png";
import the_london_roast from "../assets/trackImages/cafes/the_london_roast.png";
import kaffetid from "../assets/trackImages/cafes/kaffetid.png";
import nordic_nook from "../assets/trackImages/cafes/nordic_nook.png";
import pastelaria_pulse from "../assets/trackImages/cafes/pastelaria_pulse.png";
import porto_pour from "../assets/trackImages/cafes/porto_pour.png";
import plaza_perk from "../assets/trackImages/cafes/plaza_perk.png";

export const cafeTracks: TrackModel[] = [
	{
		id: "morning_murmur_016777",
		slug: "morning_murmur",
		name: "Morning Murmur",
		releaseDate: "2013-03-03T11:29:54",
		isPremium: false,
		description: "A gentle hum gets the day going",
		type: "file",
		collection: "cafe",
		imageUrl: morning_murmur,
		album: null,
		location: {
			venue: "Shockoe Espresso",
			address1: "104 Shockoe Slip",
			address2: null,
			cityOrLocality: "Richmond",
			stateOrProvince: "Virginia",
			country: "United States",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "lunchtime_lounge_017035",
		slug: "lunchtime_lounge",
		name: "Lunchtime Lounge",
		releaseDate: "2013-03-03T11:29:55",
		isPremium: false,
		description: "Bustling chatter of the lunchtime rush",
		type: "file",
		collection: "cafe",
		imageUrl: lunchtime_lounge,
		album: null,
		location: {
			venue: "Harrison Street Café",
			address1: "402 N Harrison St",
			address2: null,
			cityOrLocality: "Richmond",
			stateOrProvince: "Virginia",
			country: "United States",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "university_undertones_016641",
		slug: "university_undertones",
		name: "University Undertones",
		releaseDate: "2013-03-03T11:29:56",
		isPremium: false,
		description: "The scholarly sounds of a university cafe",
		type: "file",
		collection: "cafe",
		imageUrl: university_undertones,
		album: null,
		location: {
			venue: "Virginia Commonwealth University",
			address1: "901 Park Ave",
			address2: null,
			cityOrLocality: "Richmond",
			stateOrProvince: "Virginia",
			country: "United States",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "paris_paradise_016961",
		slug: "paris_paradise",
		name: "Paris Paradise",
		releaseDate: "2013-03-03T11:29:57",
		isPremium: true,
		description: "Energizing ambiance from The City of Light",
		type: "file",
		collection: "cafe",
		imageUrl: paris_paradise,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "Paris",
			stateOrProvince: "Île-de-France",
			country: "France",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "brazil_bistro_016552",
		slug: "brazil_bistro",
		name: "Brazil Bistro",
		releaseDate: "2013-03-03T11:29:58",
		isPremium: true,
		description: "The musical chatter of a Brazilian coffeehouse",
		type: "file",
		collection: "cafe",
		imageUrl: brazil_bistro,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "São Paulo",
			stateOrProvince: "São Paulo",
			country: "Brazil",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "texas_teahouse_016683",
		slug: "texas_teahouse",
		name: "Texas Teahouse",
		releaseDate: "2013-03-03T11:29:59",
		isPremium: true,
		description: "Hefty sounds from a big state",
		type: "file",
		collection: "cafe",
		imageUrl: texas_teahouse,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: null,
			stateOrProvince: "Texas",
			country: "United States",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "bogota_buzz_285407",
		slug: "bogota_buzz",
		name: "Bogotá Buzz",
		releaseDate: "2025-03-06T11:29:59",
		isPremium: true,
		description:
			"Feel the vibrant hum of a Colombian café, where the world's finest coffee fuels deep conversation and city life.",
		type: "file",
		collection: "cafe",
		imageUrl: bogota_buzz,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "Bogotá",
			stateOrProvince: "",
			country: "Columbia",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "columbia_coffeehouse_285433",
		slug: "columbia_coffeehouse",
		name: "Colombian Coffeehouse",
		releaseDate: "2025-03-06T11:29:59",
		isPremium: true,
		description:
			"A taste of London with a global twist—where the aroma of Colombian beans meets the charm of a cozy UK café.",
		type: "file",
		collection: "cafe",
		imageUrl: colombian_coffeehouse,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "London",
			stateOrProvince: "",
			country: "England",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "the_london_roast_285447",
		slug: "the_london_roast",
		name: "The London Roast",
		releaseDate: "2025-03-06T11:29:59",
		isPremium: true,
		description:
			"A classic British café ambiance—warm, bustling, and brewing with the perfect mix of espresso and chatter.",
		type: "file",
		collection: "cafe",
		imageUrl: the_london_roast,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "London",
			stateOrProvince: "",
			country: "England",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "kaffetid_285386",
		slug: "kaffetid",
		name: "Kaffetid",
		releaseDate: "2025-03-06T11:29:59",
		isPremium: true,
		description:
			"It's coffee time, Swedish-style — a cozy café corner where fika, friends, and fresh brews keep the world turning.",
		type: "file",
		collection: "cafe",
		imageUrl: kaffetid,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "Stockholm",
			stateOrProvince: "",
			country: "Sweden",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "nordic_nook_285400",
		slug: "nordic_nook",
		name: "Nordic Nook",
		releaseDate: "2025-03-06T11:29:59",
		isPremium: true,
		description:
			"A warm Scandinavian retreat, where minimalist charm meets the soothing sounds of a Stockholm café.",
		type: "file",
		collection: "cafe",
		imageUrl: nordic_nook,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "Stockholm",
			stateOrProvince: "",
			country: "Sweden",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "pastelaria_pulse_285412",
		slug: "pastelaria_pulse",
		name: "Pastelaria Pulse",
		releaseDate: "2025-03-06T11:29:59",
		isPremium: true,
		description:
			"The gentle buzz of a Portuguese café, where the espresso is rich, the pastries are sweet, and the world moves at just the right pace.",
		type: "file",
		collection: "cafe",
		imageUrl: pastelaria_pulse,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "Oeiras",
			stateOrProvince: "",
			country: "Portugal",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "porto_pour_285372",
		slug: "porto_pour",
		name: "Porto Pour",
		releaseDate: "2025-03-06T11:29:59",
		isPremium: true,
		description:
			"Sink into the laid-back rhythm of a café along the Douro River, where the coffee is strong and the streets are alive.",
		type: "file",
		collection: "cafe",
		imageUrl: porto_pour,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "Oeiras",
			stateOrProvince: "",
			country: "Portugal",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
	{
		id: "plaza_perk_285393",
		slug: "plaza_perk",
		name: "Plaza Perk",
		releaseDate: "2025-03-06T11:29:59",
		isPremium: true,
		description:
			"Grab a seat in a lively Argentinian plaza, where clinking cups, laughter, and espresso shots fuel the day.",
		type: "file",
		collection: "cafe",
		imageUrl: plaza_perk,
		album: null,
		location: {
			venue: null,
			address1: null,
			address2: null,
			cityOrLocality: "",
			stateOrProvince: "",
			country: "Argentina",
		},
		positiveEffectDescription: [],
		effectLabels: ["stimulation", "creativity", "focus"],
	},
];
