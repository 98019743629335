import mitt from "mitt";
import type { TrackModel } from "@/data/Types";

export interface ModalDetails {
	id: string;
	trackId: string;
}

export type Events = {
	closeModal: undefined;
	freemiumTimerExpired: undefined;
	showModal: ModalDetails;
	trackChanged: TrackModel;
};

const eventBus = mitt<Events>();
export default eventBus;
