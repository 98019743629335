import { useEffect, useState } from "react";
import { UpgradeToPremiumModal } from "./upgrade-to-premium-modal";
import { InterruptionModal } from "./interruption-modal";
import eventBus from "@/lib/eventBus";

export type ModalIds = "TIME_LIMIT" | "PREMIUM_REQUIRED";

const ModalController = () => {
	const [trackId, setTrackId] = useState<string | undefined>(undefined); // track id to pass to the modal
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [openedModalId, setOpenedModalId] = useState<ModalIds | undefined>(
		undefined,
	);

	const closeModal = () => {
		setIsModalOpen(false);
		setOpenedModalId(undefined); // reset the opened modal id
		setTrackId(undefined); // clear the track id when closing the modal
	};

	useEffect(() => {
		const handleShowModal = ({
			id,
			trackId,
		}: { id: string; trackId: string }) => {
			setTrackId(trackId); // set the track id to pass to the modal for analytics
			setOpenedModalId(id as ModalIds); // set the modal id to render
			setIsModalOpen(true); // this will trigger the modal to render
		};

		const handleCloseModal = () => {
			setIsModalOpen(false);
			setOpenedModalId(undefined); // reset the opened modal id
			setTrackId(undefined); // clear the track id when closing the modal
		};

		eventBus.on("showModal", handleShowModal);
		eventBus.on("closeModal", handleCloseModal);

		return () => {
			// cleanup the event listeners when the component is unmounted
			eventBus.off("showModal", handleShowModal);
			eventBus.off("closeModal", handleCloseModal);
		};
	}, []);

	return (
		<>
			<InterruptionModal
				isOpen={isModalOpen && openedModalId === "TIME_LIMIT"}
				close={closeModal}
				trackId={trackId}
			/>
			<UpgradeToPremiumModal
				isOpen={isModalOpen && openedModalId === "PREMIUM_REQUIRED"}
				close={closeModal}
				trackId={trackId}
			/>
		</>
	);
};

export default ModalController;
