import type { TrackModel } from "./Types";
import getPremiumImage from "@/assets/get-premium-1.png";

export const adTracks: TrackModel[] = [
	{
		id: "short_audio_ad_000001",
		slug: "short_audio_ad_000001",
		name: "Try Coffitivity Premium Today!",
		releaseDate: "2025-04-02T11:29:54",
		isPremium: false,
		description: "A short in audio ad by Sam",
		type: "file",
		collection: "ad",
		imageUrl: getPremiumImage,
		album: null,
		location: null,
		positiveEffectDescription: [],
		effectLabels: [],
	},
	{
		id: "long_audio_ad_000001",
		slug: "long_audio_ad_000001",
		name: "Try Coffitivity Premium Today!",
		releaseDate: "2025-04-03T11:29:54",
		isPremium: false,
		description: "A long in audio ad by Sam",
		type: "file",
		collection: "ad",
		imageUrl: getPremiumImage,
		album: null,
		location: null,
		positiveEffectDescription: [],
		effectLabels: [],
	},
];
